import { render, staticRenderFns } from "./recorderDemo.vue?vue&type=template&id=0635bbd4&scoped=true"
import script from "./recorderDemo.vue?vue&type=script&lang=js"
export * from "./recorderDemo.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0635bbd4",
  null
  
)

export default component.exports